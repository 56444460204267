/* You can add global styles to this file, and also import other style files 

@import '../node_modules/primeng/resources/themes/omega/theme.css';
*/
@import '../node_modules/primeng/resources/themes/nova/theme.css';
@import '../node_modules/primeng/resources/primeng.css';

@media (min-width: 1500px) {
    .templateBody {
        width: 80%;
        margin: auto;
    }
}

@media (max-width: 1500px) {
    .templateBody {
        width: 95%;
        margin: auto;
    }
}

.templateContainer {
    max-width: 100% !important;
}

.forYou li {
    white-space: inherit;
}

.header {
    margin-bottom: 0;
}

.hNav {
    margin-top: 30px;
}

.topNav>li:first-child {
    font-weight: normal;
    margin-left: 0;
}

.activeItem {
    font-weight: 700;
}

.t-a-l {
    text-align: left;
}

.t-a-c {
    text-align: center;
}

.p-10-20 {
    padding: 10px 20px;
}

.errorMsg {
    text-align: center;
    height: 60px;
    display: block;
    line-height: 60px;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #F44336;
    color: white;
    position: fixed;
    border-bottom: 4px #D32F2F solid;
    font-weight: bold;
    font-size: 16px;
    -webkit-animation-name: notificationMsgCls;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 1s;
    /* Safari 4.0 - 8.0 */
    animation-name: notificationMsgCls;
    animation-duration: 1s;
}

.errorMsgClose {
    text-align: center;
    height: 60px;
    display: block;
    line-height: 60px;
    top: -60px;
    left: 0;
    right: 0;
    width: 100%;
    background: #F44336;
    color: white;
    position: fixed;
    border-bottom: 4px #D32F2F solid;
    font-weight: bold;
    font-size: 16px;
    -webkit-animation-name: notificationMsgClose;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 1s;
    /* Safari 4.0 - 8.0 */
    animation-name: notificationMsgClose;
    animation-duration: 1s;
}

.successMsg {
    text-align: center;
    height: 60px;
    display: block;
    line-height: 60px;
    width: 100%;
    background: #8BC34A;
    color: white;
    position: fixed;
    border-bottom: 4px #689F38 solid;
    font-weight: bold;
    font-size: 16px;
    -webkit-animation-name: notificationMsg;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 4s;
    /* Safari 4.0 - 8.0 */
    animation-name: notificationMsg;
    animation-duration: 4s;
    top: 0;
    left: 0;
    right: 0;
}

.ui-cell-data {
    display: block;
    overflow: hidden;
}

.ui-calendar {
    width: 100%;
}

.closeBtn {
    position: fixed;
    top: -15px;
    right: 10px;
    color: white;
    cursor: pointer;
    font-size: 18px;
}

table {
    margin: 0 !important;
}

.ui-inputtext {
    background: white !important;
}


/* primeng-custom */
/* .primeng-custom .ui-tabview-panel.ui-widget-content {
    border: 1px solid #D5D5D5;
} */

/* .primeng-custom .ui-panel.ui-widget {
    margin-bottom: 15px;
} */

.primeng-custom .p-panel {
    margin-bottom: 15px;
}

/* .primeng-custom .ui-fileupload-content {
    padding: 5px;
} */

/* .primeng-custom .ui-fileupload-row div:first-child {
    display: none;
} */

/* .primeng-custom .ui-fileupload-row>div {
    padding: 0.3em 1em;
} */

/* .primeng-custom.ui-table {
    margin-bottom: 20px;
} */

.primeng-custom.p-datatable {
    margin-bottom: 30px;
}

.has-error .primeng-custom.ui-dropdown {
    border-color: #a94442;
}

.has-success .primeng-custom.ui-dropdown {
    border-color: #3c763d;
}

/* .primeng-custom */

.reduce-width {
    width: 400px;
}

.reduce-top {
    margin-top: 0px;
}

.err-msg {
    color: #a94442;
}

textarea {
    resize: vertical;
}

table.table th,
td {
    text-align: center;
}

.table>tbody>tr>td {
    vertical-align: middle;
}

.form-control.number-input {
    display: inline-block;
    width: 100px;
}

.ui-state-disabled,
.ui-widget:disabled {
    opacity: 0.6;
}

.pi-sort-alt:before {
    content: '\e903';
}

.pi-sort-amount-up-alt:before {
    content: '\e903';
}

.pi-sort-amount-down:before {
    content: '\e902';
}

.p-component {
    font-size: 1em;
}

.p-inputtext {
    font-size: 1em;
}

.p-link {
    font-size: 1em;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active {
    background: #007ad9;
    color: #FFA500;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #FFA500;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
